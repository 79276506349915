.Home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.profile_picture_contener {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dont_display {
  display: none;
}

.picture_profil {
  border-radius: 50%;
  width: 14%;
  margin-bottom: 1rem;
  filter: grayscale(100);
}

.home_box {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.home_logo {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.home_logo i {
  margin: 1rem;
  font-size: 4rem;
  transition: all 0.8s ease-in-out 0s;
  color: white;
}

.home_logo i:hover {
  color: black;
  background-color: white;
  background-size: cover;
  border-radius: 50%;
  padding: 0.7rem;
  font-size: 4.5rem;
  cursor: default;
  transform: rotate(360deg);
}

.skill {
  background-color: rgba(0, 0, 0, 0.8);
  min-width: 35%;
  max-width: 80%;
  width: auto;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 2rem;
}

.skill_text {
  background-color: rgba(0, 0, 0, 0.8);
  min-width: 50%;
  max-width: 80%;
  width: auto;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 2rem;

  max-width: 40%;
  margin-top: 1rem;
  text-align: justify;
}

@media (max-width: 768px) {
  .picture_profil {
    width: 35%;
  }

  @media (max-width: 425px) {
    .skill {
      padding: 1rem;
      margin: 0.5rem auto;

      max-width: 95%;
    }

    .skill_text {
      padding: 1rem;
      margin: 0.5rem auto;

      max-width: 95%;
    }
  }

  .skill_text {
    min-width: 70%;
  }


  .home_logo i:hover {
    color: initial;
    background-color: inherit;
    background-size: initial;
    border-radius: 0;
    padding: 0;
    font-size: 4rem;
    transform: rotate(0deg);
  }
}
