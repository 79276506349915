.project-card {
    background-color: rgb(24, 26, 27) !important;
    box-shadow: rgb(48, 52, 54) 0px 1px 3px 0px, rgb(48, 52, 54) 0px 0px 0px 1px !important;
}

.project-card-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    box-shadow: rgb(48, 52, 54) 0px 1px 3px 0px,rgb(48, 52, 54) 0px 0px 0px 1px;
}

/* trouver soluce pour mettre cette propriete */
/* .project-card-extra, .ui.card > .extra {
    border-top: 1px solid rgba(140, 130, 115, 0.05) !important;
} */

.project-card-text-color {
    color: white !important;
}

.ui.modal * {
    color: rgba(232, 230, 227, 0.85) !important;
}

.ui.modal > .header {
    background-image: initial !important;
    background-color: rgb(24, 26, 27) !important;
    box-shadow: none !important;
    border-bottom-color: rgba(128, 119, 105, 0.15) !important;
}

.ui.modal > .content {
    background-image: initial !important;
    background-color: rgb(24, 26, 27) !important;
}