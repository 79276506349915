.timeline-date {
    color: white;
    opacity: 1 !important;
    background-color: #1a1a1a;
    border-radius: 5px;
    text-align: center !important;
}

.parcours-text-container {
    opacity: 0.9 !important;
}

.parcours-label-group {
    margin: 1rem 0 0 0;
}

.parcours-label {
    opacity: none !important;
    margin: 0.15rem !important;
}

@media (max-width: 1024px) {
    .timeline-date {
        background-color: unset;
        opacity: 0.9 !important;
    }
    
}