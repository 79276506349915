* {
    margin: 0;
    padding: 0;
}

.ui.menu .item {
    color: white !important;
    font-weight: 700 !important;
}

.ui.menu .dropdown.item .menu {
    background-color: rgb(24, 26, 27) !important;
    color: white !important;
}

.item span {
    color: white !important;
    font-family: Lato,
        'Helvetica Neue',
        Arial,
        Helvetica,
        sans-serif;
}
