.Contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
}

.contact_content {
  display: flex;
  align-items: center;
  justify-content: center ;
  flex-direction: column;
  width: 35%;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2rem;
  padding: 1rem;
}

.mail_contact, .linkedin_contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  padding: 1rem;
}

@media (max-width: 768px) {
  .contact_content {
    width: 80%;
  }
  
}

.mail_contact i, .linkedin_contact i {
  margin-right: 1rem;
}

.mail_contact a, .linkedin_contact a {
  text-decoration: none;
  color: white;
}

