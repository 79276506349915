.cv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cv, .cvpdf {
  width: 100%;
  height: 100%;
}

.dont_display {
  display: none;
}